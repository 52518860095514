import { VFC, useRef, useEffect } from 'react'
import { Box, Typography, TextField, Grid, Divider, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Highlight from '@/components/Global/Highlight'
import { useHome } from './home.hooks'
import SiteCard from './SiteCard'
import SearchIcon from '@mui/icons-material/Search'
import { RoutePaths } from '@/navigation/route'

const EmptyState: VFC<{ onResetSearch?: () => void }> = ({ onResetSearch }) => {
    const { t } = useTranslation('home')
    return (
        <Grid item>
            <Typography variant="h5" gutterBottom>
                {t('emptyState.title')}
            </Typography>
            <Typography component="div" variant="subtitle1" color="textSecondary">
                {t('emptyState.descriptionNoMatchSearch')}
                <br />
                {onResetSearch && (
                    <Link component="button" onClick={onResetSearch}>
                        {t('emptyState.resetSearch')}
                    </Link>
                )}
            </Typography>
        </Grid>
    )
}
const SiteList: VFC = () => {
    const { t } = useTranslation('home')
    const { search, setSearch, handleClearSearch, allSites, matchedSites } = useHome()
    const searchInputRef = useRef<HTMLInputElement>(null)
    const handleResetSearch = () => {
        searchInputRef.current?.focus()
        handleClearSearch()
    }
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
                event.preventDefault()
                searchInputRef.current?.focus()
            }
            if (event.key === 'Escape') {
                event.preventDefault()
                handleClearSearch()
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleClearSearch])

    return (
        <Box sx={{ my: 2 }}>
            <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    {t('siteList')} ({allSites.length})
                </Typography>
                <TextField
                    value={search}
                    variant="outlined"
                    onChange={(e) => setSearch(e.target.value)}
                    sx={(theme) => ({ width: { xs: '100%', md: theme.spacing(50) } })}
                    InputProps={{
                        startAdornment: (
                            <SearchIcon sx={{ transform: 'rotateY(180deg)', color: 'text.secondary', mr: 1 }} />
                        ),
                    }}
                    placeholder={t('search')}
                    inputRef={searchInputRef}
                />
            </Grid>
            <Divider />
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {matchedSites.map(({ item: site, ranges }) => (
                    <Grid item key={site.siteId} xs={12} sm={6} md={4} lg={3}>
                        <SiteCard
                            to={`${RoutePaths.Sites}/${site.hash}`}
                            title={<Highlight variant="h6" text={site.name} matchedIndexRanges={ranges} />}
                            site={site}
                        />
                    </Grid>
                ))}
                {allSites.length > 0 && matchedSites.length === 0 && <EmptyState onResetSearch={handleResetSearch} />}
            </Grid>
        </Box>
    )
}

export default SiteList
